import styled from 'styled-components';

export const StyledMachineList = styled.div`
  ${(props): string => props.theme.mixins.layout.addHeaderContentClass('machine-list')}
  ${(props): string => props.theme.mixins.layout.addBodyContentClass('machine-list')}
  ${(props): string => props.theme.mixins.layout.addContainerClasses('machine-list')}

  min-height: inherit;

  .machine-list__header {
    background-color: transparent;
  }

  .machine-list__page-info {
    display: flex;
    justify-content: space-between;
  }

  .machine-list__header-buttons {
    width: 610px;
    display: flex;
    justify-content: flex-end;
    gap: ${(props): string => props.theme.spacing.base};
  }

  .machine-list__title {
    ${(props): string => props.theme.fontStyles.redesign.heading1};
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .machine-list__filter {
    display: flex;
    justify-content: flex-start;
    gap: ${(props): string => props.theme.spacing.base};
    padding-top: 24px;

    .ant-select {
      width: 100%;

      .ant-select-selector {
        background-color: ${(props): string => props.theme.colors.white};

        .select__dropdown-label {
          padding-right: 20px;
        }
      }
    }

    .ant-form-item .ant-form-item-control-input {
      min-height: 32px;
    }
  }

  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-control-input {
      min-height: 32px;
    }
  }

  .machine-list__search-bar {
    min-width: 270px;
    margin-left: auto;
  }

  .machine-list__search-bar-icon {
    width: 16px;
    height: 16px;

    i,
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .machine-list__input {
    display: flex;
    flex-direction: row-reverse;
    width: 50%;
    padding: 0px;
    padding-left: 14px !important;
    padding: 0px;

    .ant-input-prefix {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      border-left: 1px solid ${(props): string => props.theme.colors.darkGrey};
      padding: 14px;
    }

    input {
      top: 0px !important;
    }
  }

  .machine-list__table {
    margin-bottom: 40px;

    .ant-table-row {
      cursor: pointer;
    }
  }

  .machine-list__export-icon {
    path {
      fill: ${(props): string => props.theme.colors.black};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-bottom: -2px;
    }
  }

  .machine-list__filter .machine-list__preset-select.ant-select {
    width: max-content;
    max-width: 350px;
  }

  .machine-list__filter-button,
  .machine-list__columns-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: ${(props): string => props.theme.spacing.xs};
    ${(props): string => props.theme.fontStyles.redesign.base.strong};
  }

  .machine-list__filter-button-icon,
  .machine-list__columns-button-icon {
    width: 14px;
    svg {
      margin-bottom: -2px;
    }
  }

  .machine-list__filter-button-counter {
    line-height: 20px;
    margin-top: -2px;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;

    color: ${(props): string => props.theme.colors.black};
    background-color: ${(props): string => props.theme.colors.white};
  }

  .machine-list__categories-form {
    min-width: 260px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${(props): string => props.theme.spacing.base};
  }

  .machine-list__categories-form-label {
    margin-top: 2px;
  }

  .machine-list__machine-type-select-wrapper {
    min-width: 110px;
  }

  .machine-list__site-select-wrapper {
    min-width: 110px;
  }

  .machine-list__machine-status-select-wrapper {
    min-width: 110px;
    .ant-select-selection-wrap {
      padding-right: 20px;
    }
  }

  .machine-list__material-number-select-wrapper {
    min-width: 155px;
  }

  .machine-list__classification-select-wrapper {
    min-width: 100px;
    .ant-select-selection-wrap {
      padding-right: 20px;
    }
  }

  .machine-list__finance-type-select-wrapper {
    min-width: 130px;
  }

  .machine-list__service-agreement-type-select-wrapper {
    min-width: 215px;
  }

  .machine-list__filter-label {
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    line-height: ${(props): string => props.theme.lineHeights.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
  }

  .machine-list__filter-select {
    width: 100%;
  }

  .button-active {
    background-color: ${(props): string => props.theme.colors.black} !important;
    color: white !important;
    path {
      fill: ${(props): string => props.theme.colors.white};
    }
  }

  .machine-list__filter-wrapper-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    gap: ${(props): string => props.theme.spacing.base};
    margin-top: ${(props): string => props.theme.spacing.lg};
  }

  .machine-list__filter-wrapper-container--hidden {
    display: none;
  }

  // Needs to match scroll.y prop on <Table>
  .ant-table-container,
  .ant-table-body {
    // Disable until decision is made from PO side
    // min-height: 600px;
  }

  .table__title {
    display: inline;
  }

  .machine-list__machine-type-tooltip-icon,
  .machine-list__machine-planned-hours-tooltip-icon,
  .machine-list__machine-operating-hours-tooltip-icon,
  .machine-list__machine-average-hours-tooltip-icon,
  .machine-list__machine-total-hours-tooltip-icon,
  .machine-list__machine-activation-tooltip-icon,
  .machine-list__machine-contract-tooltip-icon {
    margin-left: ${(props): string => props.theme.spacing.xxs};
  }

  .ant-table-container {
    table {
      .ant-table-tbody {
        tr > td.machine-list__category-column,
        tr > td.machine-list__classification-column {
          padding: 0;
        }
        tr > td.machine-list__category-column {
          .machine-list__battery-status-critical {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            border-left: 3px solid red;
          }
        }
        tr > td.machine-list__deviation-column,
        tr > td.machine-list__status-column {
          padding: ${(props): string => props.theme.spacing.xxs} ${(props): string => props.theme.spacing.sm};
        }
        tr > td.machine-list__battery-column {
          .machine-list__battery-indicator {
            justify-content: center;
          }
        }
      }
    }
  }

  .table__site-name-button {
    height: 0;
    padding: 0;

    span {
      :hover {
        text-decoration: underline;
      }
    }
  }

  .ant-form-item-control-input-content {
    display: flex;
  }

  .machine-list__battery-status-critical {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .machine-battery-status__warning {
    height: 22px;
    margin-bottom: 5px;
    svg {
      color: ${(props): string => props.theme.colors.semanticWarning};
      width: 22px;
      height: 22px;
    }
  }

  .machine-list__name {
    ${(props): string => props.theme.mixins.truncateText};
  }
`;
