import { TFunction } from 'react-i18next';
import { i18n as i18next } from 'i18next';
import React from 'react';
import isNil from 'lodash-es/isNil';
import { SorterResult } from 'antd/es/table/interface';
import { Icon } from '../../../../../../../lib/components/Icon/Icon';
import { Tooltip } from '../../../../../../../lib/components/Tooltip/Tooltip';
import { DateTime } from '../../../../../../../lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from '../../../../../../../lib/utils/date-handling/DateTime.types';
import {
  MachinesReportUser,
  Column as MachineListColumn,
  BatteryDischargeStatus,
} from '../../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DeviationStatusPill } from '../../../../../cleaning/components/DeviationStatusPill/DeviationStatusPill';
import { MachineCategoryIndicator } from '../../../../components/MachineCategoryIndicator/MachineCategoryIndicator';
import { MachineClassificationIndicator } from '../../../../components/MachineClassificationIndicator/MachineClassificationIndicator';
import { MachineStatusIndicator } from '../../../../components/MachineStatusIndicator/MachineStatusIndicator';
import { MachineGeofenceStatusIndicator } from '../../../../components/MachineGeofenceStatusIndicator/MachineGeofenceStatusIndicator';
import { MachineAsReport } from '../../../../interfaces/Machine.types';
import { ColumnsTypeForCustomizableTable } from '../../../../../../../lib/components/Table/Table';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { MachineBatteryDischargeStatusIndicator } from 'app/modules/machine-inventory/components/MachineStatusIndicator/MachineStatusBatteryDeepCharge/MachineStatusBatteryDeepCharge';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { FulfillmentStatusPill } from 'app/modules/cleaning/components/FulFillmentStatusPill/FulFillmentPill';

export { MachineListColumn };

enum MachineListColumnKey {
  isIoTDevice = 'isIoTDevice',
  metadataName = 'metadata.name',
  machineTypeName = 'machineTypeName',
  metadataInventoryNumber = 'metadata.inventoryNumber',
  siteName = 'site.name',
  statisticsPlannedOperatingTimeInMs = 'statistics.plannedOperatingTimeInMs',
  statisticsActualOperatingTimeInMs = 'statistics.actualOperatingTimeInMs',
  statisticsDeviationInMs = 'statistics.deviationInMs',
  statisticsCleaningDays = 'statistics.cleaningDays',
  statisticsAvgWorkingTimePerDayInMs = 'statistics.avgWorkingTimePerDayInMs',
  statisticDeviationInPercentage = 'statistics.deviationInPercentage',
  statisticFullfilmentInPerCentage = 'statistics.fullfilmentInPerCentage',
  statisticPlannedShifts = 'statistics.plannedShifts',
  statisticSumMissedCleaningJobs = 'statistics.sumMissedCleaningJobs',
  totalOperatingTimeInSeconds = 'totalOperatingTimeInSeconds',
  siteManagersFullName = 'site.managers.fullName',
  activatedAt = 'activatedAt',
  lastActivityAt = 'lastActivityAt',
  geofenceStatus = 'geofenceStatus',
  lastConnectedAt = 'lastConnectedAt',
  materialNumber = 'materialNumber',
  serialNumber = 'serialNumber',
  batteryDischargeStatus = 'batteryDischargeStatus',
  metadataFinanceFinanceType = 'metadata.finance.financeType',
  metadataFinanceContractNumber = 'metadata.finance.contractNumber',
  metadataServiceServicePackage = 'metadata.service.servicePackage',
  address = 'address',
  accuracy = 'accuracy',
}

export const getMachineListColumns = ({
  t,
  i18n,
  handleClickSiteName,
  sortedInfo,
  isTooltipVisible,
  setIsTooltipVisible,
  isBatteryDeepDischargeEnabled,
  isLocationEnabled,
  isGeofenceStatusEnabled,
  isCleaningKpisEnabled,
}: {
  t: TFunction;
  i18n: i18next;
  handleClickSiteName: (siteId: string) => void;
  sortedInfo: SorterResult<MachineAsReport>;
  isTooltipVisible: {
    type: boolean;
    planned: boolean;
    operating: boolean;
    average: boolean;
    total: boolean;
    activation: boolean;
    contract: boolean;
  };
  setIsTooltipVisible: (value: {
    type: boolean;
    planned: boolean;
    operating: boolean;
    average: boolean;
    total: boolean;
    activation: boolean;
    contract: boolean;
  }) => void;
  isBatteryDeepDischargeEnabled: boolean;
  isLocationEnabled: boolean;
  isGeofenceStatusEnabled: boolean;
  isCleaningKpisEnabled: boolean;
}): ColumnsTypeForCustomizableTable<MachineListColumn, MachineAsReport> => [
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.Category,
    columnLabel: t('machineList.table.classification'),
    // Should not appear in column customizer, therefore will always be shown
    customizable: false,
    // ColumnCustomizerPanel properties // end
    dataIndex: 'isIoTDevice',
    key: MachineListColumnKey.isIoTDevice,
    sorter: false,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.isIoTDevice ? sortedInfo.order : undefined,
    className: 'machine-list__category-column',
    render: (_, machineAsReport: MachineAsReport): React.JSX.Element => (
      <div
        className={`${
          isBatteryDeepDischargeEnabled &&
          machineAsReport.batteryDischargeStatus &&
          machineAsReport.batteryDischargeStatus === BatteryDischargeStatus.Critical
            ? 'machine-list__battery-status-critical'
            : ''
        }`}
      >
        <MachineCategoryIndicator
          category={machineAsReport.classification}
          className="machine-list__classification-indicator"
        />
      </div>
    ),
    width: 54,
    align: 'center',
    ellipsis: {
      showTitle: true,
    },
    fixed: true,
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.MachineName,
    columnLabel: t('machineList.table.name'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.name')}>
        {t('machineList.table.name')}
      </div>
    ),
    dataIndex: ['metadata', 'name'],
    key: MachineListColumnKey.metadataName,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.metadataName ? sortedInfo.order : undefined,
    fixed: 'left',
    width: 180,
    render: (name: string, machineAsReport: MachineAsReport): React.JSX.Element => (
      <div
        className={`${
          isBatteryDeepDischargeEnabled &&
          machineAsReport.batteryDischargeStatus &&
          machineAsReport.batteryDischargeStatus === BatteryDischargeStatus.Critical
            ? 'machine-list__battery-status-critical'
            : ''
        }`}
      >
        <Tooltip title={name}>
          <div className="machine-list__name">
            {name || `${machineAsReport.machineTypeName} / ${machineAsReport.serialNumber}`}
          </div>
        </Tooltip>
        {machineAsReport.batteryDischargeStatus &&
          machineAsReport.batteryDischargeStatus === BatteryDischargeStatus.Critical && (
            <SvgIcon name="warning" className={`machine-battery-status__warning`} />
          )}
      </div>
    ),
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.MachineType,
    columnLabel: t('machineList.table.type'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <>
        <div className="table__title" title={t('machineList.table.type')}>
          {t('machineList.table.deviceName')}
        </div>
        <Tooltip
          className="machine-list__machine-type-tooltip"
          overlayClassName="machine-list__machine-type-tooltip-overlay"
          title={t('machineList.table.tooltip.type')}
          open={isTooltipVisible.type}
        >
          <span
            onMouseEnter={(): void => setIsTooltipVisible({ ...isTooltipVisible, type: true })}
            onMouseLeave={(): void => setIsTooltipVisible({ ...isTooltipVisible, type: false })}
          >
            <Icon name="icon-a_002_information_circle" className="machine-list__machine-type-tooltip-icon" />
          </span>
        </Tooltip>
      </>
    ),
    dataIndex: ['machineTypeName'],
    key: MachineListColumnKey.machineTypeName,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.machineTypeName ? sortedInfo.order : undefined,
    defaultSortOrder: 'ascend',
    width: 180,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.InventoryNumber,
    columnLabel: t('machineList.table.inventoryNo'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.inventoryNo')}>
        {t('machineList.table.inventoryNo')}
      </div>
    ),
    dataIndex: ['metadata', 'inventoryNumber'],
    key: MachineListColumnKey.metadataInventoryNumber,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.metadataInventoryNumber ? sortedInfo.order : undefined,
    width: 134,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.SiteName,
    columnLabel: t('machineList.table.site'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.site')}>
        {t('machineList.table.site')}
      </div>
    ),
    render: (_, machineAsReport: MachineAsReport): React.JSX.Element | undefined => (
      <TextButton
        className="table__site-name-button"
        onClick={(e): void => {
          e.stopPropagation();
          if (!machineAsReport.site?.siteId) return;
          handleClickSiteName(machineAsReport.site.siteId);
        }}
      >
        {machineAsReport.site?.name}
      </TextButton>
    ),
    dataIndex: ['site', 'name'],
    key: MachineListColumnKey.siteName,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.siteName ? sortedInfo.order : undefined,
    width: 242,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.PlannedOperatingHours,
    columnLabel: t('machineList.table.planned'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <>
        <div className="table__title" title={t('machineList.table.planned')}>
          {t('machineList.table.planned')}
        </div>
        <Tooltip
          className="machine-list__machine-planned-hours-tooltip"
          overlayClassName="machine-list__machine-planned-hours-tooltip-overlay"
          title={t('machineList.table.tooltip.planned')}
          open={isTooltipVisible.planned}
        >
          <span
            onMouseEnter={(): void => setIsTooltipVisible({ ...isTooltipVisible, planned: true })}
            onMouseLeave={(): void => setIsTooltipVisible({ ...isTooltipVisible, planned: false })}
          >
            <Icon name="icon-a_002_information_circle" className="machine-list__machine-planned-hours-tooltip-icon" />
          </span>
        </Tooltip>
      </>
    ),
    dataIndex: ['statistics', 'plannedOperatingTimeInMs'],
    key: MachineListColumnKey.statisticsPlannedOperatingTimeInMs,
    sorter: true,
    sortOrder:
      sortedInfo.columnKey === MachineListColumnKey.statisticsPlannedOperatingTimeInMs ? sortedInfo.order : undefined,
    render: (plannedOperatingTimeMs: number): React.JSX.Element => (
      <>{DateTime.formatDurationByMilliseconds({ ms: plannedOperatingTimeMs ?? 0 })}</>
    ),
    width: 120,
    align: 'right',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.OperatingHours,
    columnLabel: t('machineList.table.operating'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <>
        <div className="table__title" title={t('machineList.table.operating')}>
          {t('machineList.table.operating')}
        </div>
        <Tooltip
          className="machine-list__machine-operating-hours-tooltip"
          overlayClassName="machine-list__machine-operating-hours-tooltip-overlay"
          title={t('machineList.table.tooltip.operating')}
          open={isTooltipVisible.operating}
        >
          <span
            onMouseEnter={(): void => setIsTooltipVisible({ ...isTooltipVisible, operating: true })}
            onMouseLeave={(): void => setIsTooltipVisible({ ...isTooltipVisible, operating: false })}
          >
            <Icon name="icon-a_002_information_circle" className="machine-list__machine-operating-hours-tooltip-icon" />
          </span>
        </Tooltip>
      </>
    ),
    dataIndex: ['statistics', 'actualOperatingTimeInMs'],
    key: MachineListColumnKey.statisticsActualOperatingTimeInMs,
    sorter: true,
    sortOrder:
      sortedInfo.columnKey === MachineListColumnKey.statisticsActualOperatingTimeInMs ? sortedInfo.order : undefined,
    render: (actualOperatingTimeMs: number): React.JSX.Element => (
      <>{DateTime.formatDurationByMilliseconds({ ms: actualOperatingTimeMs ?? 0 })}</>
    ),
    width: 135,
    align: 'right',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.Deviation,
    columnLabel: t('machineList.table.deviation'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.deviation')}>
        {t('machineList.table.deviation')}
      </div>
    ),
    render: (machineAsReport: MachineAsReport): React.JSX.Element => {
      if (machineAsReport?.statistics?.plannedOperatingTimeInMs === 0) {
        return (
          <Tooltip
            className="machine-list__deviation"
            overlayClassName="machine-list__deviation-tooltip-overlay"
            title={t('cleaningReportList.totals.table.columns.deviationTooltip')}
          >
            <span>
              {t('common.NA')}{' '}
              <Icon name="icon-a_002_information_circle" className="machine-list__deviation-tooltip-icon" />
            </span>
          </Tooltip>
        );
      }

      const deviationPercentage = Math.round(machineAsReport?.statistics?.deviationInPercentage ?? 0);

      return <DeviationStatusPill deviationPercent={deviationPercentage} />;
    },
    key: MachineListColumnKey.statisticDeviationInPercentage,
    sorter: true,
    sortOrder:
      sortedInfo.columnKey === MachineListColumnKey.statisticDeviationInPercentage ? sortedInfo.order : undefined,
    className: 'machine-list__deviation-column',
    width: 120,
    align: 'right',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.CleaningDays,
    columnLabel: t('machineList.table.cleaningDays'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.cleaningDays')}>
        {t('machineList.table.cleaningDays')}
      </div>
    ),
    dataIndex: ['statistics', 'cleaningDays'],
    key: MachineListColumnKey.statisticsCleaningDays,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.statisticsCleaningDays ? sortedInfo.order : undefined,
    render: (cleaningDays: number): React.JSX.Element => <>{cleaningDays}</>,
    width: 140,
    align: 'right',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.AvgWorkingTimePerDay,
    columnLabel: t('machineList.table.avgWorkingTimePerDayInMs'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <>
        <div className="table__title" title={t('machineList.table.avgWorkingTimePerDayInMs')}>
          {t('machineList.table.avgWorkingTimePerDayInMs')}
        </div>
        <Tooltip
          className="machine-list__machine-average-hours-tooltip"
          overlayClassName="machine-list__machine-average-hours-tooltip-overlay"
          title={t('machineList.table.tooltip.average')}
          open={isTooltipVisible.average}
        >
          <span
            onMouseEnter={(): void => setIsTooltipVisible({ ...isTooltipVisible, average: true })}
            onMouseLeave={(): void => setIsTooltipVisible({ ...isTooltipVisible, average: false })}
          >
            <Icon name="icon-a_002_information_circle" className="machine-list__machine-average-hours-tooltip-icon" />
          </span>
        </Tooltip>
      </>
    ),
    dataIndex: ['statistics', 'avgWorkingTimePerDayInMs'],
    key: MachineListColumnKey.statisticsAvgWorkingTimePerDayInMs,
    sorter: true,
    sortOrder:
      sortedInfo.columnKey === MachineListColumnKey.statisticsAvgWorkingTimePerDayInMs ? sortedInfo.order : undefined,
    render: (avgWorkingTimePerDayInMs: number): React.JSX.Element => (
      <>{DateTime.formatDurationByMilliseconds({ ms: avgWorkingTimePerDayInMs ?? 0 })}</>
    ),
    width: 120,
    align: 'right',
    ellipsis: {
      showTitle: true,
    },
  },

  {
    hidden: !isCleaningKpisEnabled,
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.PlannedShifts,
    columnLabel: t('machineList.table.plannedShifts'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.plannedShifts')}>
        {t('machineList.table.plannedShifts')}
      </div>
    ),
    dataIndex: ['statistics', 'plannedShifts'],
    key: MachineListColumnKey.statisticPlannedShifts,
    render: (plannedShifts: number): React.JSX.Element => <>{plannedShifts}</>,
    width: 140,
    align: 'right',
    ellipsis: {
      showTitle: true,
    },
  },

  {
    hidden: !isCleaningKpisEnabled,
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.SumMissedCleaningJobs,
    columnLabel: t('machineList.table.sumMissedCleaningJobs'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.sumMissedCleaningJobs')}>
        {t('machineList.table.sumMissedCleaningJobs')}
      </div>
    ),
    dataIndex: ['statistics', 'sumMissedCleaningJobs'],
    key: MachineListColumnKey.statisticSumMissedCleaningJobs,
    render: (sumMissedCleaningJobs: number): React.JSX.Element => <>{sumMissedCleaningJobs}</>,
    width: 140,
    align: 'right',
    ellipsis: {
      showTitle: true,
    },
  },

  {
    hidden: !isCleaningKpisEnabled,
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.FullfillmentInPercentage,
    columnLabel: t('machineList.table.fullfilmentInPerCentage'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.fullfilmentInPerCentage')}>
        {t('machineList.table.fullfilmentInPerCentage')}
      </div>
    ),
    key: MachineListColumnKey.statisticFullfilmentInPerCentage,
    render: (machineAsReport: MachineAsReport): React.JSX.Element => {
      if (machineAsReport.statistics?.plannedShifts === 0 && machineAsReport.statistics?.sumMissedCleaningJobs === 0) {
        return (
          <Tooltip
            className="machine-list__fulfillment"
            overlayClassName="machine-list__fulfillment-tooltip-overlay"
            title={t('machineList.table.tooltip.fulfillment')}
          >
            <span>
              {t('common.NA')}{' '}
              <Icon name="icon-a_002_information_circle" className="machine-list__fulfillment-tooltip-icon" />
            </span>
          </Tooltip>
        );
      }
      const fulfillmentRound = Math.round(machineAsReport.statistics?.fullfilmentInPerCentage ?? 0);
      return <FulfillmentStatusPill fulfillmentPercent={fulfillmentRound} />;
    },
    width: 140,
    align: 'right',
    ellipsis: {
      showTitle: true,
    },
  },

  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.TotalOperatingHours,
    columnLabel: t('machineList.table.total'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <>
        <div className="table__title" title={t('machineList.table.total')}>
          {t('machineList.table.total')}
        </div>
        <Tooltip
          className="machine-list__machine-total-hours-tooltip"
          overlayClassName="machine-list__machine-total-hours-tooltip-overlay"
          title={t('machineList.table.tooltip.total')}
          open={isTooltipVisible.total}
        >
          <span
            onMouseEnter={(): void => setIsTooltipVisible({ ...isTooltipVisible, total: true })}
            onMouseLeave={(): void => setIsTooltipVisible({ ...isTooltipVisible, total: false })}
          >
            <Icon name="icon-a_002_information_circle" className="machine-list__machine-total-hours-tooltip-icon" />
          </span>
        </Tooltip>
      </>
    ),
    dataIndex: 'totalOperatingTimeInSeconds',
    key: MachineListColumnKey.totalOperatingTimeInSeconds,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.totalOperatingTimeInSeconds ? sortedInfo.order : undefined,
    render: (totalOperatingTimeInSeconds: number): React.JSX.Element => (
      <>
        {DateTime.formatDurationByMilliseconds({ ms: DateTime.convertSecondsToMs(totalOperatingTimeInSeconds) ?? 0 })}
      </>
    ),
    width: 120,
    align: 'right',
    ellipsis: {
      showTitle: true,
    },
  },

  {
    hidden: !isBatteryDeepDischargeEnabled,
    columnId: MachineListColumn.BatteryDischargeStatus,
    columnLabel: t('machineList.table.batteryVoltage'),
    customizable: isBatteryDeepDischargeEnabled ?? false,
    title: (
      <div className="table__title" title={t('machineList.table.batteryVoltage')}>
        {t('machineList.table.batteryVoltage')}
      </div>
    ),
    dataIndex: 'batteryDischargeStatus',
    key: MachineListColumnKey.batteryDischargeStatus,
    className: 'machine-list__battery-column',
    render: (_, batteryDischargeStatus: MachineAsReport): React.JSX.Element => (
      <MachineBatteryDischargeStatusIndicator
        status={batteryDischargeStatus.batteryDischargeStatus}
        shortText
        className="machine-list__battery-indicator"
      />
    ),
    width: 144,
    align: 'center',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.SiteManagers,
    columnLabel: t('machineList.table.siteManager'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.siteManager')}>
        {t('machineList.table.siteManager')}
      </div>
    ),
    render: (machineAsReport: MachineAsReport): React.JSX.Element | undefined => (
      <>{machineAsReport.site?.managers.map((manager: MachinesReportUser) => manager.fullName).join(', ')}</>
    ),
    key: MachineListColumnKey.siteManagersFullName,
    sorter: false,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.siteManagersFullName ? sortedInfo.order : undefined,
    width: 180,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.ActivationDate,
    columnLabel: t('machineList.table.activation'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <>
        <div className="table__title" title={t('machineList.table.activation')}>
          {t('machineList.table.activation')}
        </div>
        <Tooltip
          className="machine-list__machine-activation-tooltip"
          overlayClassName="machine-list__machine-activation-tooltip-overlay"
          title={t('machineList.table.tooltip.activation')}
          open={isTooltipVisible.activation}
        >
          <span
            onMouseEnter={(): void => setIsTooltipVisible({ ...isTooltipVisible, activation: true })}
            onMouseLeave={(): void => setIsTooltipVisible({ ...isTooltipVisible, activation: false })}
          >
            <Icon name="icon-a_002_information_circle" className="machine-list__machine-activation-tooltip-icon" />
          </span>
        </Tooltip>
      </>
    ),
    dataIndex: 'activatedAt',
    key: MachineListColumnKey.activatedAt,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.activatedAt ? sortedInfo.order : undefined,
    render: (activatedAt: string): React.JSX.Element | undefined => (
      <>{DateTime.formatDateByLocale(i18n.language, activatedAt, DATE_FORMAT_PATTERN.DATE)}</>
    ),
    width: 190,
    align: 'right',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.Classification,
    columnLabel: t('machineList.table.classification'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.classification')}>
        {t('machineList.table.classification')}
      </div>
    ),
    dataIndex: 'isIoTDevice',
    key: MachineListColumnKey.isIoTDevice,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.isIoTDevice ? sortedInfo.order : undefined,
    className: 'machine-list__classification-column',
    render: (_, machineAsReport: MachineAsReport): React.JSX.Element => (
      <MachineClassificationIndicator
        isIoTDevice={machineAsReport.isIoTDevice}
        className="machine-list__classification-indicator"
      />
    ),
    width: 132,
    align: 'center',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.DataStatus,
    columnLabel: t('machineList.table.dataStatus'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.dataStatus')}>
        {t('machineList.table.dataStatus')}
      </div>
    ),
    dataIndex: 'lastActivityAt',
    key: MachineListColumnKey.lastActivityAt,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.lastActivityAt ? sortedInfo.order : undefined,
    className: 'machine-list__status-column',
    render: (_, machineAsReport: MachineAsReport): React.JSX.Element => (
      <MachineStatusIndicator
        lastActivityAt={machineAsReport.lastActivityAt}
        className="machine-list__status-indicator"
      />
    ),
    width: 144,
    align: 'center',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.LastConnection,
    columnLabel: t('machineList.table.lastConnection'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.lastConnection')}>
        {t('machineList.table.lastConnection')}
      </div>
    ),
    dataIndex: 'lastConnectedAt',
    key: MachineListColumnKey.lastConnectedAt,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.lastConnectedAt ? sortedInfo.order : undefined,
    render: (lastConnectedAt: string): React.JSX.Element | undefined => (
      <>{DateTime.formatDateByLocale(i18n.language, lastConnectedAt, DATE_FORMAT_PATTERN.DATE_TIME)}</>
    ),
    width: 200,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    hidden: !isGeofenceStatusEnabled,
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.GeofenceStatus,
    columnLabel: t('machineList.table.geofenceStatus'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.geofenceStatus')}>
        {t('machineList.table.geofenceStatus')}
      </div>
    ),
    dataIndex: 'geofenceStatus',
    key: MachineListColumnKey.geofenceStatus,
    className: 'machine-list__status-column',
    render: (_, machineAsReport: MachineAsReport): React.JSX.Element => (
      <MachineGeofenceStatusIndicator
        machineGeofenceStatus={machineAsReport.geofenceStatus}
        className="machine-list__geofence-status-indicator"
      />
    ),
    width: 144,
    align: 'center',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    hidden: !isLocationEnabled,
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.Address,
    columnLabel: t('machineList.table.address'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.address')}>
        {t('machineList.table.address')}
      </div>
    ),
    dataIndex: 'address',
    key: MachineListColumnKey.address,
    width: 180,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    hidden: !isLocationEnabled,
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.Accuracy,
    columnLabel: t('machineList.table.accuracy'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.accuracy')}>
        {t('machineList.table.accuracy')}
      </div>
    ),
    dataIndex: 'accuracy',
    key: MachineListColumnKey.accuracy,
    render: (accuracy: number): React.JSX.Element => (accuracy ? <>{accuracy} m</> : <></>),
    width: 144,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.MaterialNumber,
    columnLabel: t('machineList.table.materialNo'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.materialNo')}>
        {t('machineList.table.materialNo')}
      </div>
    ),
    dataIndex: 'materialNumber',
    key: MachineListColumnKey.materialNumber,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.materialNumber ? sortedInfo.order : undefined,
    width: 123,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.SerialNumber,
    columnLabel: t('machineList.table.serialNo'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.serialNo')}>
        {t('machineList.table.serialNo')}
      </div>
    ),
    dataIndex: 'serialNumber',
    key: MachineListColumnKey.serialNumber,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.serialNumber ? sortedInfo.order : undefined,
    width: 105,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.FinanceContractType,
    columnLabel: t('machineList.table.contract'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <>
        <div className="table__title" title={t('machineList.table.contract')}>
          {t('machineList.table.contract')}
        </div>
        <Tooltip
          className="machine-list__machine-contract-tooltip"
          overlayClassName="machine-list__machine-contract-tooltip-overlay"
          title={t('machineList.table.tooltip.contract')}
          open={isTooltipVisible.contract}
        >
          <span
            onMouseEnter={(): void => setIsTooltipVisible({ ...isTooltipVisible, contract: true })}
            onMouseLeave={(): void => setIsTooltipVisible({ ...isTooltipVisible, contract: false })}
          >
            <Icon name="icon-a_002_information_circle" className="machine-list__machine-contract-tooltip-icon" />
          </span>
        </Tooltip>
      </>
    ),
    render: (financeType, _machineAsReport: MachineAsReport): React.JSX.Element =>
      !isNil(financeType) ? <>{t(`machineModals.editMachineInfo.financeType.${financeType}`)}</> : <></>,
    dataIndex: ['metadata', 'finance', 'financeType'],
    key: MachineListColumnKey.metadataFinanceFinanceType,
    sorter: true,
    sortOrder: sortedInfo.columnKey === MachineListColumnKey.metadataFinanceFinanceType ? sortedInfo.order : undefined,
    width: 134,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.FinanceContractNumber,
    columnLabel: t('machineList.table.contractNo'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.contractNo')}>
        {t('machineList.table.contractNo')}
      </div>
    ),
    dataIndex: ['metadata', 'finance', 'contractNumber'],
    key: MachineListColumnKey.metadataFinanceContractNumber,
    sortOrder:
      sortedInfo.columnKey === MachineListColumnKey.metadataFinanceContractNumber ? sortedInfo.order : undefined,
    sorter: true,
    width: 123,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    // ColumnCustomizerPanel properties // start
    columnId: MachineListColumn.ServiceAgreementType,
    columnLabel: t('machineList.table.serviceAgreement'),
    customizable: true,
    // ColumnCustomizerPanel properties // end
    title: (
      <div className="table__title" title={t('machineList.table.serviceAgreement')}>
        {t('machineList.table.serviceAgreement')}
      </div>
    ),
    render: (servicePackage, _machineAsReport: MachineAsReport): React.JSX.Element =>
      !isNil(servicePackage) ? <>{t(`machineModals.editMachineInfo.servicePackage.${servicePackage}`)}</> : <></>,
    dataIndex: ['metadata', 'service', 'servicePackage'],
    key: MachineListColumnKey.metadataServiceServicePackage,
    sorter: true,
    sortOrder:
      sortedInfo.columnKey === MachineListColumnKey.metadataServiceServicePackage ? sortedInfo.order : undefined,
    width: 169,
    ellipsis: {
      showTitle: true,
    },
  },
];
